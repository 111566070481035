import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
    name: 'numberToCurrency',
})
export class NumberToCurrencyPipe implements PipeTransform {
    transform(value: any, currency: string = '?'): string {
        if (!value) {
            return '0';
        }
        //console.log('NumberToCurrencyPipe::transform()')
        const currencySymbol = this.getCurrencySymbol(currency);
        return `${currencySymbol} ${Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 2,
            currency: currency,
        }).format(value.toFixed(2))}`;
    }

    private getCurrencySymbol(currency: string): string {
      switch (currency) {
        case 'EUR':
          return '€';
        case 'USD':
          return '$';
        case 'GBP':
          return '£';
        case 'JPY':
          return '¥';
        case 'DKK':
          return 'kr';
        case 'CHF':
          return 'CHF';
        case 'QAR':
          return 'QAR';
        case 'CZK':
          return 'Kč';
        case 'PLN':
          return 'zł';
        case 'MAD':
          return 'MAD';
        case 'SEK':
          return 'kr';
        case 'NOK':
          return 'kr';
        default:
          return currency;
      }
  }
}
